import axios, { AxiosPromise, Method } from 'axios'

const API = (
    methodType: Method,
    endpoint: string,
    payload?: any
): AxiosPromise => {
    return axios({
        method: methodType,
        url: endpoint,
        data: payload,
    })
}

export const setAuthToken = ({
    token,
}: {
    token: string | undefined
}): void => {
    if (token) {
        axios.defaults.headers.common['auth-token'] = token
    }
}

export default API
