import React from 'react'
import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { InitialAppStateTypes } from 'redux/reducers'
import configureStore from 'redux/store'
import { setAuthToken } from 'utilities/axios'
import { loadState, saveState } from 'utilities/sessionStorage'
import throttle from 'utilities/funcs/throttle'
import App from './App'
import reportWebVitals from './reportWebVitals'

const persistedState: InitialAppStateTypes | undefined = loadState()
const store = configureStore(persistedState)

if (!axios.defaults.headers.common['auth-token']) {
    const token: any | undefined = store.getState()?.user?.authToken
    if (token) {
        setAuthToken({ token })
    }
}

const saveUserState = (): void => {
    const { user, serviceInfo } = store.getState()
    if (user?.authToken) {
        saveState({ user, serviceInfo })
    }
}
store.subscribe(throttle(saveUserState, 1000))

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <App store={store} />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
