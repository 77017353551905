/* Container */
import { useSelector } from 'react-redux'
import { InitialAppStateTypes } from 'redux/reducers'
import StyledLoader from './Loader.styles'

const Loader = () => {
    const load = useSelector((state: InitialAppStateTypes) => state.load!)

    return <StyledLoader show={load.isLoading} />
}

export default Loader
