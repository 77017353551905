import { combineReducers } from 'redux'
import { ResponseActionType } from '../actions'
import {
    UserStateTypes,
    ServiceInfoStateTypes,
    RangePriceStateTypes,
    dynamicPriceReducer,
    goaPriceReducer,
    serviceInfoReducer,
    userReducer,
} from './dynamicPrice'
import { LoaderStateTypes, loaderReducer } from './notification'

export interface InitialAppStateTypes {
    user?: UserStateTypes
    serviceInfo?: ServiceInfoStateTypes
    dynamicPrice?: RangePriceStateTypes
    goaPrice?: RangePriceStateTypes
    load?: LoaderStateTypes
}

const appReducer = combineReducers({
    user: userReducer,
    serviceInfo: serviceInfoReducer,
    dynamicPrice: dynamicPriceReducer,
    goaPrice: goaPriceReducer,
    load: loaderReducer,
})

export const rootReducer = (state: any, action: ResponseActionType) => {
    return appReducer(state, action)
}
