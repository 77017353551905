import {
    ERROR_OCCUR,
    ERROR_REMOVE,
    HIDE_LOADING,
    SHOW_LOADING,
} from 'redux/actions/notification'
import { ResponseActionType } from '../actions'

export interface LoaderStateTypes {
    isLoading: boolean
    isError: boolean
    errorMsg?: string
}

export const loaderInitialState: LoaderStateTypes = {
    isLoading: false,
    isError: false,
    errorMsg: '',
}

export const loaderReducer = (
    state = loaderInitialState,
    action: ResponseActionType
): LoaderStateTypes => {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMsg: '',
            }
        case HIDE_LOADING:
            return {
                ...state,
                isLoading: false,
            }
        case ERROR_OCCUR:
            return {
                ...state,
                isError: true,
                errorMsg: action?.response?.error,
            }
        case ERROR_REMOVE:
            return {
                ...state,
                isError: false,
                errorMsg: '',
            }
        default:
            return state
    }
}
