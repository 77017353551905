import { all, call, put, takeLatest } from 'redux-saga/effects'
import { HTTP_GET, HTTP_PUT } from 'utilities/apis'
import { PRICE_RANGE_API, GOA_RANGE_API } from 'utilities/apis/dynamicPrice'
import { TAGS_API } from 'utilities/apis/jobs'
import API, { setAuthToken } from 'utilities/axios'
import { forwardTo } from 'utilities/history'
import { loadState } from 'utilities/sessionStorage'
import {
    AUTH_TOKEN,
    DYNAMIC_PRICE,
    GOA_PRICE,
    PRICE,
    SERVICE_INFO,
} from '../actions/dynamicPrice'
import {
    ERROR_OCCUR,
    HIDE_LOADING,
    SHOW_LOADING,
} from '../actions/notification'
import { ServiceInfoStateTypes } from '../reducers/dynamicPrice'

export function* rangeRates(): Generator {
    try {
        yield put({ type: SHOW_LOADING })
        let serviceInfo: ServiceInfoStateTypes | undefined
        const searchParams = new URLSearchParams(window.location.search)

        if (searchParams.get('authToken')) {
            const authToken: string = searchParams.get('authToken')!
            yield put({ type: AUTH_TOKEN, response: { authToken } })
            setAuthToken({ token: authToken })
            const categoryType: string = searchParams.get('categoryType')!
            const currency: string = searchParams.get('currency')!
            const serviceId = Number(searchParams.get('serviceId')!)
            const jobNumber = Number(searchParams.get('jobNumber')!)
            const zip: string = searchParams.get('zip')!
            const btaId = Number(searchParams.get('btaId')!) || undefined
            const towMiles = Number(searchParams.get('towMiles')!) || undefined
            const timeZone: string = searchParams.get('timeZone')!
            const partnerCode: string = searchParams.get('partnerCode')!

            serviceInfo = {
                categoryType,
                currency,
                serviceId,
                zip,
                jobNumber,
                btaId,
                towMiles,
                timeZone,
                partnerCode,
            }
            yield put({ type: SERVICE_INFO, response: serviceInfo })
            forwardTo('/')
        } else {
            serviceInfo = loadState()?.serviceInfo
        }

        const [dynamicPriceResponse, goaResponse]: any = yield all([
            call(
                API,
                HTTP_GET,
                PRICE_RANGE_API(
                    serviceInfo?.serviceId,
                    serviceInfo?.btaId,
                    serviceInfo?.towMiles,
                    serviceInfo?.zip,
                    serviceInfo?.timeZone,
                    serviceInfo?.partnerCode
                )
            ),
            call(
                API,
                HTTP_GET,
                GOA_RANGE_API(serviceInfo?.serviceId, serviceInfo?.towMiles)
            ),
        ])

        dynamicPriceResponse.data.currency = serviceInfo?.currency
        goaResponse.data.currency = serviceInfo?.currency
        yield all([
            put({ type: DYNAMIC_PRICE, response: dynamicPriceResponse.data }),
            put({ type: GOA_PRICE, response: goaResponse.data }),
        ])

        const tagData = [
            {
                tag: 'national.pricing.lower.bound',
                tagValue: dynamicPriceResponse?.data?.lowerBound
                    ? dynamicPriceResponse.data.lowerBound
                    : '',
            },
            {
                tag: 'national.pricing.upper.bound',
                tagValue: dynamicPriceResponse?.data?.upperBound
                    ? dynamicPriceResponse.data.upperBound
                    : '',
            },
        ]
        yield call(API, HTTP_PUT, TAGS_API(serviceInfo?.jobNumber), tagData)
    } catch (error: any) {
        if (error?.response?.data) {
            yield put({ type: ERROR_OCCUR, response: error.response.data })
        } else {
            yield put({ type: ERROR_OCCUR, response: { error: error.message } })
        }
    }

    yield put({ type: HIDE_LOADING })
}

function* dynamicPriceWatcher(): Generator {
    yield takeLatest(PRICE, rangeRates)
}

export default dynamicPriceWatcher
