/* Presentational */
import { Container, Grid } from '@mui/material'
import translation from 'utilities/languages'
import ServiceInfo from './components/ServiceInfoTable'
import ServiceRates from './components/ServiceRatesTable'
import {
    StyledCard,
    StyledCardHeader,
    StyledCardContent,
    StyledTypography,
    Div,
} from './ServiceApprovedRates.styles'

const ServiceApprovedRatesForm = () => (
    <Container maxWidth="md">
        <Grid item sm={12}>
            <StyledCard elevation={0} variant="outlined">
                <StyledCardHeader
                    id="dynamic.price.header"
                    title={translation.PAGE_TITLE.RATES}
                    titleTypographyProps={{ fontWeight: 'bolder' }}
                />
                <StyledCardContent>
                    <Div>
                        <ServiceInfo />
                        <ServiceRates />
                    </Div>
                </StyledCardContent>
            </StyledCard>
            <StyledTypography id="important.message" variant="body2">
                <b>{translation.LABEL.IMPORTANT}</b>
                <span>&nbsp; {translation.MESSAGE.IMPORTANT.NORMAL}</span>
                <b>&nbsp; {translation.MESSAGE.IMPORTANT.BOLD}</b>
            </StyledTypography>
        </Grid>
    </Container>
)

export default ServiceApprovedRatesForm
