import { Grid, Table, TableRow, TableCell } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledTable = styled(Table)({
    padding: '20px 15px 0 0',
    borderBottom: 'none',
    width: '125%',
})
export const StyledGoaTableRow = styled(TableRow)({
    backgroundColor: '#fbfbfb',
})
export const StyledBasicTableRow = styled(TableRow)({
    backgroundColor: '#e0eef2',
    paddingRight: '15px',
})
export const StyledTableCell = styled(TableCell)({
    padding: '12px 12px 12px 12px',
    borderBottom: 'none',
})
export const StyledGrid = styled(Grid)({
    paddingRight: '15px',
})
