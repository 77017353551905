import languageEN from 'assets/languages/languageEN'

const translations = {
    translate: (() => {
        switch (process.env.REACT_APP_LANGUAGE) {
            case 'EN':
            default:
                return languageEN
        }
    })(),
}

export default translations.translate
