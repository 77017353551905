/* Presentational */
import { Backdrop, CircularProgress } from '@mui/material'

export interface LoaderProps {
    show: boolean
}

const LoaderForm = ({ show }: LoaderProps) => (
    <Backdrop open={show} id="loader.form" data-testid="loader.form">
        <CircularProgress color="inherit" />
    </Backdrop>
)

export default LoaderForm
