import { InitialAppStateTypes } from 'redux/reducers'

const PERSIST_STORE_KEY = 'pricing-tool'

export const loadState = (): InitialAppStateTypes | undefined => {
    try {
        const serializedState = sessionStorage.getItem(PERSIST_STORE_KEY)
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (err) {
        return undefined
    }
}

export const saveState = (state: InitialAppStateTypes): void => {
    try {
        const serializedState = JSON.stringify(state)
        sessionStorage.setItem(PERSIST_STORE_KEY, serializedState)
    } catch (err) {
        // Ignore write errors.
    }
}

export const clear = (): void => {
    try {
        sessionStorage.clear()
    } catch (err) {
        // Ignore write errors.
    }
}
