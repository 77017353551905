import { styled } from '@mui/material/styles'
import LoaderForm from './Loader'

const StyledLoader = styled(LoaderForm)({
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: '1301',
    color: '#02baf2',
    position: 'absolute',
})

export default StyledLoader
