interface ThrottleOptions {
    leading: boolean | null
    trailing: boolean | null
}

function throttle(func: () => any, wait: number, options?: ThrottleOptions) {
    let context: any
    let args: any
    let result: any
    let timeout: null | NodeJS.Timeout = null
    let previous = 0
    // eslint-disable-next-line
    if (!options) options = { leading: null, trailing: null }
    const later = () => {
        previous = options?.leading === false ? 0 : Date.now()
        timeout = null
        result = func.apply(context, args)
        if (!timeout) {
            context = args
            args = null
        }
    }
    // eslint-disable-next-line
    return function (...tArgs: any) {
        const now = Date.now()
        if (!previous && options?.leading === false) previous = now
        const remaining = wait - (now - previous)
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        context = this
        args = tArgs
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout)
                timeout = null
            }
            previous = now
            result = func.apply(context, args)
            if (!timeout) {
                context = args
                args = null
            }
        } else if (!timeout && options?.trailing !== false) {
            timeout = setTimeout(later, remaining)
        }
        return result
    }
}
export default throttle
