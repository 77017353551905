import { Grid, Table, TableRow, TableCell } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledTable = styled(Table)({
    display: 'flex',
    justifySelf: 'flex-start',
    height: '100%',
    width: '100%',
})
export const StyledTableRow = styled(TableRow)({
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '10px',
})
export const StyledTableCell = styled(TableCell)({
    padding: '0 10px 10px 15px',
    borderBottom: 'none',
})
export const StyledGrid = styled(Grid)({
    paddingTop: '15px',
})
