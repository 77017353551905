import { HOST_URL } from '.'

export const PRICE_RANGE_API = (
    serviceId: number | undefined,
    btaId: number | undefined,
    towMiles: number | undefined,
    zip: string | undefined,
    timeZone: string | undefined,
    partnerCode: string | undefined
): string => {
    return `${HOST_URL}/ops/price-negotiation-tool?categoryId=${serviceId}${
        btaId ? `&btaId=${btaId}` : ''
    }${towMiles ? `&towMiles=${towMiles}` : ''}${zip ? `&zip=${zip}` : ''}${
        timeZone ? `&timeZone=${timeZone}` : ''
    }${partnerCode ? `&partnerCode=${partnerCode}` : ''}`
}

export const GOA_RANGE_API = (
    serviceId: number | undefined,
    towMiles: number | undefined
): string => {
    return `${HOST_URL}/ops/price-negotiation-tool/goa?categoryId=${serviceId}${
        towMiles ? `&towMiles=${towMiles}` : ''
    }`
}
