const translation = {
    PAGE_TITLE: {
        RATES: `Service Approved Rates`,
    },
    LABEL: {
        SERVICE_TYPE: `Service Type:`,
        TOW_MILES: `Tow Miles:`,
        ZIP_CODE: `Zip Code:`,
        LOWER_RANGE: `Lower Range`,
        MID_RANGE: `Mid Range`,
        UPPER_RANGE: `Upper Range`,
        BASE_SERVICE: `Base Service`,
        GOA: `GOA`,
        IMPORTANT: `IMPORTANT:`,
    },
    MESSAGE: {
        IMPORTANT: {
            NORMAL: `The rates provided for the completed rate and GOA rate are based upon the market rate for the given service type in your area.`,
            BOLD: `Any rate above the upper range must be approved by a supervisor.`,
        },
    },
}

export default translation
