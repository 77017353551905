/* Container */
import { useSelector } from 'react-redux'
import { InitialAppStateTypes } from 'redux/reducers'
import ServiceRatesTable from './ServiceRatesTable'

const ServiceRates = () => {
    const dynamicPrice = useSelector(
        (state: InitialAppStateTypes) => state.dynamicPrice!
    )
    // const goaPrice = useSelector(
    //     (state: InitialAppStateTypes) => state.goaPrice!
    // )

    return <ServiceRatesTable baseService={dynamicPrice} />
}

export default ServiceRates
