import { Card, CardHeader, CardContent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledCard = styled(Card)({
    margin: 'auto',
    width: '80%',
})
export const StyledCardHeader = styled(CardHeader)({
    padding: '0.75rem 1.25rem',
    marginBottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
})
export const StyledCardContent = styled(CardContent)({
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
})
export const StyledTypography = styled(Typography)({
    padding: '0.75rem 1.25rem',
    marginBottom: '0',
    paddingTop: '15px',
})
export const Div = styled('div')({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginRight: '6rem',
})
