import { applyMiddleware, createStore, Store, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootReducer, InitialAppStateTypes } from './reducers'
import rootSaga from './saga'

let store: Store<InitialAppStateTypes>

const configureStore = (
    initialState: InitialAppStateTypes | undefined
): Store<InitialAppStateTypes> => {
    const sagaMiddleware = createSagaMiddleware()

    const composeEnhancers: any =
        (window as never)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose
    const middlewares = composeEnhancers(applyMiddleware(sagaMiddleware))
    store = createStore(rootReducer, initialState, middlewares)
    sagaMiddleware.run(rootSaga)
    return store
}

export const getStore = (): Store<InitialAppStateTypes> => {
    return store
}

export default configureStore
