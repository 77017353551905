/* Presentational */
import { TableBody } from '@mui/material'
import translation from 'utilities/languages'
import {
    StyledGrid,
    StyledTable,
    StyledTableCell,
    StyledTableRow,
} from './ServiceInfoTable.styles'

interface ServiceInfoProps {
    categoryType: string
    zip: string | undefined
    towMiles: number | undefined
}

const ServiceInfoTable = ({
    categoryType,
    zip,
    towMiles,
}: ServiceInfoProps) => (
    <StyledGrid item sm={4}>
        <StyledTable>
            <TableBody>
                <StyledTableRow>
                    <StyledTableCell
                        id="service.type.label"
                        sx={{ width: '100px' }}
                    >
                        <b>{translation.LABEL.SERVICE_TYPE}</b>
                    </StyledTableCell>
                    <StyledTableCell id="service.type.data">
                        {categoryType}
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell
                        id="tow.miles.label"
                        sx={{ width: '100px' }}
                    >
                        <b>{translation.LABEL.TOW_MILES}</b>
                    </StyledTableCell>
                    <StyledTableCell id="tow.miles.data">
                        {towMiles || 'N/A'}
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell
                        id="zip.code.label"
                        sx={{ width: '100px' }}
                    >
                        <b>{translation.LABEL.ZIP_CODE}</b>
                    </StyledTableCell>
                    <StyledTableCell>{zip || 'N/A'}</StyledTableCell>
                </StyledTableRow>
            </TableBody>
        </StyledTable>
    </StyledGrid>
)

export default ServiceInfoTable
