/* Presentational */
import { TableBody, TableHead, TableRow } from '@mui/material'
import { RangePriceStateTypes } from 'redux/reducers/dynamicPrice'
import translation from 'utilities/languages'
import {
    StyledBasicTableRow,
    StyledGoaTableRow,
    StyledGrid,
    StyledTable,
    StyledTableCell,
} from './ServiceRatesTable.styles'

interface ServiceRatesProps {
    baseService: RangePriceStateTypes
    // goa: RangePriceStateTypes
}

const ServiceRatesTable = ({ baseService }: ServiceRatesProps) => (
    <StyledGrid item sm={8}>
        <StyledTable>
            <TableHead>
                <TableRow>
                    <StyledTableCell sx={{ width: '35%' }} />
                    <StyledTableCell>
                        <b>{translation.LABEL.LOWER_RANGE}</b>
                    </StyledTableCell>
                    <StyledTableCell>
                        <b>{translation.LABEL.MID_RANGE}</b>
                    </StyledTableCell>
                    <StyledTableCell>
                        <b>{translation.LABEL.UPPER_RANGE}</b>
                    </StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <StyledBasicTableRow>
                    <StyledTableCell sx={{ width: '35%' }}>
                        <b>{translation.LABEL.BASE_SERVICE}</b>
                    </StyledTableCell>
                    <StyledTableCell
                        id="base.service.lower.bound"
                        sx={{ width: '15%' }}
                    >
                        {baseService?.lowerBound
                            ? `${baseService.lowerBound.toFixed(2)} ${
                                  baseService.currency
                              }`
                            : 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell
                        id="base.service.middle.bound"
                        sx={{ width: '15%' }}
                    >
                        {baseService?.midBound
                            ? `${baseService.midBound.toFixed(2)} ${
                                  baseService.currency
                              }`
                            : 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell
                        id="base.service.upper.bound"
                        sx={{ width: '15%' }}
                    >
                        {baseService?.upperBound
                            ? `${baseService.upperBound.toFixed(2)} ${
                                  baseService.currency
                              }`
                            : 'N/A'}
                    </StyledTableCell>
                </StyledBasicTableRow>
                <StyledGoaTableRow>
                    <StyledTableCell sx={{ width: '35%' }}>
                        <b>{translation.LABEL.GOA}</b>
                    </StyledTableCell>
                    <StyledTableCell id="goa.lower.bound" colSpan={3}>
                        <span>
                            Do not negotiate GOA unless the provider is out of
                            network - 50% GOA is acceptable for
                            <b> out of network</b>
                        </span>
                    </StyledTableCell>
                </StyledGoaTableRow>
            </TableBody>
        </StyledTable>
    </StyledGrid>
)

export default ServiceRatesTable
