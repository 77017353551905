/* Container */
import { useSelector } from 'react-redux'
import { InitialAppStateTypes } from 'redux/reducers'
import ServiceInfoTable from './ServiceInfoTable'

const ServiceInfo = () => {
    const serviceInfo = useSelector(
        (state: InitialAppStateTypes) => state.serviceInfo!
    )

    return (
        <ServiceInfoTable
            categoryType={serviceInfo.categoryType}
            towMiles={serviceInfo.towMiles}
            zip={serviceInfo.zip}
        />
    )
}

export default ServiceInfo
