/* Container */
import { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SnackbarOrigin } from '@mui/material'
import { InitialAppStateTypes } from 'redux/reducers'
import { ERROR_REMOVE } from 'redux/actions/notification'
import AlertBar from './Alert'

export interface AlertProps extends SnackbarOrigin {
    severityType: 'error' | 'info' | 'success' | 'warning'
}

const Alert = ({ vertical, horizontal, severityType }: AlertProps) => {
    const load = useSelector((state: InitialAppStateTypes) => state.load!)
    const dispatch = useDispatch()

    const alertClose = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        dispatch({ type: ERROR_REMOVE })
    }

    return (
        <AlertBar
            severityType={severityType}
            show={load.isError}
            handleClose={alertClose}
            vertical={vertical}
            horizontal={horizontal}
            message={load?.errorMsg}
        />
    )
}

export default Alert
