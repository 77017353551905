// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory, History } from 'history'

/* Create browser history for react-router-dom. */
const history: History = createBrowserHistory()

/* Forward URL link to next page. */
export const forwardTo = (location: string): void => {
    history.push(location)
}

export default history
