import {
    AUTH_TOKEN,
    SERVICE_INFO,
    DYNAMIC_PRICE,
    GOA_PRICE,
} from '../actions/dynamicPrice'
import { ResponseActionType } from '../actions'

export interface UserStateTypes {
    authToken: string
}

export interface ServiceInfoStateTypes {
    categoryType: string
    currency: string
    serviceId: number
    jobNumber: number
    zip: string | undefined
    btaId: number | undefined
    towMiles: number | undefined
    timeZone: string | undefined
    partnerCode: string | undefined
}

export interface RangePriceStateTypes {
    lowerBound?: number
    upperBound?: number
    midBound?: number
    currency?: string
}

const userInitialState: UserStateTypes = {
    authToken: '',
}

const serviceInfoInitialState: ServiceInfoStateTypes = {
    categoryType: '',
    currency: '',
    serviceId: 0,
    jobNumber: 0,
    zip: undefined,
    btaId: undefined,
    towMiles: undefined,
    timeZone: undefined,
    partnerCode: undefined,
}

const dynamicPriceInitialState: RangePriceStateTypes = {}

export const userReducer = (
    state = userInitialState,
    action: ResponseActionType
): UserStateTypes => {
    switch (action.type) {
        case AUTH_TOKEN:
            return {
                ...state,
                ...action.response,
            }
        default:
            return state
    }
}

export const serviceInfoReducer = (
    state = serviceInfoInitialState,
    action: ResponseActionType
): ServiceInfoStateTypes => {
    switch (action.type) {
        case SERVICE_INFO:
            return {
                ...state,
                ...action.response,
            }
        default:
            return state
    }
}

export const dynamicPriceReducer = (
    state = dynamicPriceInitialState,
    action: ResponseActionType
): RangePriceStateTypes => {
    switch (action.type) {
        case DYNAMIC_PRICE:
            return {
                ...state,
                ...action.response,
            }
        default:
            return state
    }
}

export const goaPriceReducer = (
    state = dynamicPriceInitialState,
    action: ResponseActionType
): RangePriceStateTypes => {
    switch (action.type) {
        case GOA_PRICE:
            return {
                ...state,
                ...action.response,
            }
        default:
            return state
    }
}
