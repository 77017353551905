/* Container */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PRICE } from 'redux/actions/dynamicPrice'
import Alert from 'components/Alert'
import ServiceApprovedRatesForm from './ServiceApprovedRates'

const ServiceApprovedRates = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: PRICE })
    }, [])

    return (
        <>
            <Alert severityType="error" vertical="top" horizontal="center" />
            <ServiceApprovedRatesForm />
        </>
    )
}

export default ServiceApprovedRates
