import { Provider } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Store } from 'redux'
import { StyledEngineProvider } from '@mui/material/styles'
import history from './utilities/history'
import DynamicPrice from './pages/ServiceApprovedRates'
import Loader from './components/Loader'

interface AppProps {
    store: Store
}

const App = ({ store }: AppProps) => (
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <Loader />
            <HashRouter>
                <Routes location={history.location}>
                    <Route path="/" element={<DynamicPrice />} />
                </Routes>
            </HashRouter>
        </StyledEngineProvider>
    </Provider>
)

export default App
