/* Presentational */
import { Alert, Snackbar, SnackbarOrigin } from '@mui/material'

export interface AlertProps extends SnackbarOrigin {
    severityType: 'error' | 'info' | 'success' | 'warning'
    show: boolean
    handleClose: () => void
    message?: string
}

const AlertBar = ({
    vertical,
    horizontal,
    severityType,
    show,
    handleClose,
    message,
}: AlertProps) => (
    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={10000}
        id="alert.snack.bar"
        data-testid="alert.snack.bar"
        onClose={handleClose}
        open={show}
    >
        <Alert
            variant="filled"
            severity={severityType}
            onClose={handleClose}
            id="alert.message"
            data-testid="alert.message"
        >
            {message}
        </Alert>
    </Snackbar>
)

export default AlertBar
